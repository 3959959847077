import React, { useState, useEffect } from "react";
import googleLogo from './google/btn_google_signin_dark_normal_web@2x.png'
import googleLogoFocus from './google/btn_google_signin_dark_focus_web@2x.png'
import * as crypto from 'crypto';
import querystring from 'querystring';
import { setToken } from './features/auth/authSlice';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { Redirect } from "react-router";


const location = window.location.protocol + '//' + window.location.hostname + (window.location.port !== '80' && window.location.port !== '' ? ':' + window.location.port : '');

export const Login = () => {
    const [windowObjectReference , setWindowObjectReference] = useState<WindowProxy | null>(null);
    const [previousUrl, setPreviousUrl] = useState("");
    const dispatch = useAppDispatch();

    const token = useAppSelector((state) => state.auth.token);

    useEffect(() => {
        if (!window.opener) {
            return;
        }
        
        window.opener.postMessage(window.location.search);
        window.close();
    });

    const receiveMessage = (event: any) => {
        if (event.origin !== location) {
            return;
        }
        const { data } = event;
    
        const decodedData = querystring.parse(data.replace("?", ""));
        console.log(decodedData.token);
        dispatch(setToken(decodedData.token as string));
    }

    const openGooglePopup = () => {
        const url = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id='
                        + process.env.REACT_APP_GOOGLE_CLIENT_ID + '&scope=openid%20email&'
                        + 'redirect_uri=' + process.env.REACT_APP_API_HOST + '/social/google/callback&'
                        + 'nonce=' + crypto.randomBytes(20).toString('hex')
                        + '&state=origin%3D' + encodeURIComponent(
                            location + '/login/callback'
                        );
        
        const name = 'Google auth popup'
        
        window.removeEventListener('message', receiveMessage);
        
        const strWindowFeatures =
           'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

        if (windowObjectReference === null || windowObjectReference.closed) {
            setWindowObjectReference(window.open(url, name, strWindowFeatures));
        } else if (previousUrl !== url) {
            setWindowObjectReference(window.open(url, name, strWindowFeatures));
            windowObjectReference!.focus();
        } else {
            windowObjectReference.focus();
        }

        window.addEventListener('message', event => receiveMessage(event), false);
        setPreviousUrl(url);
    }

    return (
        (token.length > 0) 
            ? <Redirect to="/" /> 
            : <div>
                <img src={googleLogo} 
                    onMouseOver={e => (e.currentTarget.src = googleLogoFocus)}
                    onMouseOut={e => (e.currentTarget.src = googleLogo)} 
                    onClick={() => openGooglePopup()} 
                    alt="Login with Google"/>
                
            </div>
    );
}


export default Login;