import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import watchlistReducer from '../features/dashboard/watchlistSlice';
import currentSymbolReducer from '../features/dashboard/currentSymbolSlice';
import scannerSlice from '../features/dashboard/scannerSlice';
import mutelistSlice from '../features/dashboard/scanner/muteListSlice';

const LOCALSTORAGE_KEY = 'kilmulis_data';

const loadState = () => {
    try {
        const serializedState = localStorage.getItem(LOCALSTORAGE_KEY);
        if (serializedState === null) return {};
        const state = JSON.parse(serializedState);
        delete state.wheel;
        //delete state.mutelist;
        
        return state;
    } catch (e) {
        console.warn(e);
    }
    return {};
}

const saveState = (state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(LOCALSTORAGE_KEY, serializedState);
    } catch (e) {
        console.warn(e);
    }
}

const store = configureStore({
    reducer: {
      auth: authReducer,
      watchlist: watchlistReducer,
      symbol: currentSymbolReducer,
      wheel: scannerSlice,
      mutelist: mutelistSlice,
    },
    preloadedState: loadState()
});

store.subscribe(() => saveState(store.getState()));


export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;