import {
    Route,
    Redirect,
  } from "react-router-dom";
import { useAppSelector } from '../../app/hooks';

type PrivateRouteProps = {
    component: any,
    path: string,
    exact: boolean
};

export const PrivateRoute = ({ component, path, exact} : PrivateRouteProps) => {
    const token = useAppSelector((state) => state.auth.token);
    return (
        <Route exact={exact} path={path} render={(location) => (token.length > 0) 
            ? component() 
            : <Redirect to={{pathname: "/login"}}/>} />  
    )
};
