import {  useAppSelector } from '../../app/hooks';


export const OptionDetails = () => {
    const symbol = useAppSelector((state) => state.symbol.value) || 'AAPL';
    const items = useAppSelector((state) => state.wheel.items);
    
    const options: any = items.find((value: any) => value.symbol === symbol);
   
    const finalOptions = options?.options || [];
    const itemList = finalOptions.map((item: any) => 
        //  <div className="col-6 ps-1 pl-0 pr-0 pe-0 pt-1" >
        // style={{width: "5rem", fontSize: "0.3rem"}}
            <div key={item._id}  className={"card w-50  " + ((item.roi > 50) ? 'border-warning': 'border-success') }>
                <div className="card-body p-0 pt-1 ">
                    <h6 style={{fontSize: "0.9rem"}} className="card-title">{item.date}</h6>
                    <p className="card-text">Strike: {item.strike} <br /> Roi: {Math.round(item.roi * 100) / 100 }</p>
                    
                 </div>
            </div>
        // </div>
    );

    return (
        <div className="overflow-auto " >
            <div className="row m-0">
                {itemList}
            </div>
        </div>
    );
}