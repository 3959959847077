import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect } from "react";
import { setValue } from './currentSymbolSlice';
import { fetchItems } from './scannerSlice';
import { MutePopup } from './scanner/mutePopup';
import { ListGroup } from 'react-bootstrap';
import { getList } from './scanner/muteListSlice';

export const Scanner = () => {
    
    const dispatch = useAppDispatch();
    
    const items = useAppSelector((state) => state.wheel.items);
    const wheelStatus = useAppSelector((state) => state.wheel.status);
    const symbol = useAppSelector((state) => state.symbol.value) || 'AAPL';
    const muteList = useAppSelector((state) => state.mutelist);
    
    useEffect(() => {
        if (wheelStatus === 'idle') {
          dispatch(fetchItems())
        }
    });
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);

    const filteredList = items.filter((item:any) => {
        
        if (muteList[item.symbol] === undefined) {
            return true;
        }
        
        if (muteList[item.symbol] > currentTimestamp) {
            return false;
        }
        return true;
    });
    const itemList = filteredList.map((item: any) => {
        const activeClass = item.symbol === symbol ? ' active' : '';
        return (
            <ListGroup.Item
                as="li"
                className={"list-group-item py-0" + activeClass} key={item.symbol}>
                <div onClick={() => dispatch(setValue(item.symbol))}>
                    <span className="">{item.symbol}</span>
                    <MutePopup symbol={item.symbol} />
                </div>
            </ListGroup.Item>
        );
    });

    return (
        <div className="overflow-auto scanner" >
            <div className="progress" style={{height: "1px"}}>
                <div className="progress-bar" role="progressbar" style={{width: "25%"}} />
            </div>
            <ListGroup as="ol" className="list-group list-group-flush">
                {itemList}
            </ListGroup>
        </div>
    );
}