import { AdvancedChart } from "react-tradingview-embed";
import { useAppSelector } from '../../app/hooks';

export const Chart = () => {
    const symbol = useAppSelector((state) => state.symbol.value) || 'AAPL';
    
    return (
        <AdvancedChart widgetProps={{
            "theme": "light",
            "symbol": symbol,
            "allow_symbol_change": true,
            interval: "D",
            range: "12m",
            height: "800px"
            
        }} widgetPropsAny= {{
            // studies: [{
            //     id: "KLYTwEdM-Lowest-Close",
            //     type: "pinescript"
            // }],
            // studies_access: {
            //     type: "white",
            //     tools: [
            //         {
            //             name: "KLYTwEdM-Lowest-Close",
            //             grayed: false
            //         },
            //     ]
            // }
        }}
        />
    );
}


// <!-- TradingView Widget BEGIN -->
// <div class="tradingview-widget-container">
//   <div id="tradingview_93c8c"></div>
//   <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/BTCUSDT/?exchange=BINANCE" rel="noopener" target="_blank"><span class="blue-text">BTCUSDT Chart</span></a> by TradingView</div>
//   <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
//   <script type="text/javascript">
//   new TradingView.widget(
//   {
//   "autosize": true,
//   "symbol": "BINANCE:BTCUSDT",
//   "interval": "D",
//   "timezone": "Etc/UTC",
//   "theme": "light",
//   "style": "1",
//   "locale": "en",
//   "toolbar_bg": "#f1f3f6",
//   "enable_publishing": false,
//   "hide_side_toolbar": false,
//   "allow_symbol_change": true,
//   "details": true,
//   "calendar": true,
//   "studies": [
//     "ChaikinOscillator@tv-basicstudies",
//     "chandeMO@tv-basicstudies",
//     "ChoppinessIndex@tv-basicstudies",
//     "CCI@tv-basicstudies",
//     "CRSI@tv-basicstudies"
//   ],
//   "container_id": "tradingview_93c8c"
// }
//   );
//   </script>
// </div>
// <!-- TradingView Widget END -->