import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../app/store'

interface MuteList {
    [key: string]: number
}

interface addSymbolAction {
    symbol: string,
    expiration: number
}

const initialState = {
   
} as MuteList;

export const muteListSlice = createSlice({
    name: 'mutelist',
    initialState,
    reducers: {
        addSymbol: (state, action: PayloadAction<addSymbolAction>) => {
            const exp = Math.floor(new Date().getTime() / 1000) + action.payload.expiration;
            state[action.payload.symbol] = exp;
        }
    }
});

export const { addSymbol } = muteListSlice.actions;
export const getList = (state: RootState) => state.mutelist;

export default muteListSlice.reducer;