import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

interface Watchlist {
    items: string[]
}

const initialState = {
    items: []
} as Watchlist;

export const watchlistSlice = createSlice({
    name: 'watchlist',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<string>) => {
            if (!state.items.find((value, index) => value === action.payload)) {
                state.items.push(action.payload);
            }
        },
        deleteItem: (state, action) => {
            state.items = state.items.filter((value, index) => value !== action.payload);
        }
    }
});

export const { addItem, deleteItem } = watchlistSlice.actions;
export const getItems = (state: RootState) => state.watchlist.items;

export default watchlistSlice.reducer;