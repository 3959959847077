
import { Chart } from "./chart";
// import { Watchlist } from "./watchlist";
import { Scanner } from "./scanner";
import { OptionDetails } from "./optionDetails";
import { Checklist } from "./checklist";

export const Dashboard = () => {
    return (
       <div>
           <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
               <a href="/" className="navbar-brand col-md-3 col-lg-2 me-0 px-3">Kilmulis</a>
               <input className="form-control form-control-dark w-100" type="text" placeholder="Symbol" aria-label="Symbol" />
                <div className="navbar-nav">
                    <div className="nav-item text-nowrap">
                        <a className="nav-link px-3" href="/logout">Sign out</a>
                    </div>
               </div>
           </header>
           <div className="container-fluid main-area">
                <div className="row">
                    <main className="col-10 p-0">
                        <Chart />
                        <div className="container-fluid main-area">
                            <Checklist />
                        </div>
                    </main>
                    <div className="col-md-2 border p-0">
                        {/* <div className="position-sticky pt-3 bg-light h-50 border"> */}
                            <Scanner />
                        {/* </div> */}
                        {/* <div className="position-sticky bg-light "> */}
                            <OptionDetails />
                        {/* </div> */}
                    </div>
                </div>
            </div>
            
       </div>
    );
}