import { useAppSelector } from '../../app/hooks';
import { useEffect } from "react";

export const Checklist = () => {
    const symbol = useAppSelector((state) => state.symbol.value) || 'AAPL';
    let value = false;

    useEffect(() => {
        value = false;
    });
    
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <h2>Want to sell puts for {symbol}?</h2>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <input className="form-check-input me-1" type="checkbox" value={symbol}/>
                            Do you want to own a stock?
                        </li>
                        <li className="list-group-item">
                            <input className="form-check-input me-1" type="checkbox" value={symbol} defaultChecked={false}/>
                            Do you want to own a stock at this strike price?
                        </li>
                        <li className="list-group-item">
                            <input className="form-check-input me-1" type="checkbox" value={symbol} defaultChecked={false}/>
                            Is there no earning before exp date?
                        </li>
                        <li className="list-group-item">
                            <input className="form-check-input me-1" type="checkbox" value="" />
                            Do you see a strong support above strike price?
                        </li>
                        <li className="list-group-item">
                            <input className="form-check-input me-1" type="checkbox" value="" />
                            Did you check news? Why it's down?
                        </li>
                        <li className="list-group-item">
                            <input className="form-check-input me-1" type="checkbox" value="" />
                            Do you want stocks from this country?
                        </li>
                        <li className="list-group-item">
                            <input className="form-check-input me-1" type="checkbox" value="" />
                            Is this stock non-pharma?
                        </li>
                        <li className="list-group-item">
                            <input className="form-check-input me-1" type="checkbox" value="" />
                            Is this stock trading above 5$ line for the past 6 month?
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}