import './App.css';
import Login from './Login';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { PrivateRoute } from './features/auth/auth';
import { Dashboard} from './features/dashboard/dashboard';

function App() {
  return (
    <Router>
      <div className="app app-header">
        <Route path="/login" component={Login} exact={true} />
        <Route path="/login/callback" component={Login} />
        <PrivateRoute path="/" component={Dashboard} exact={true} />
      </div>
    </Router>
  );
}

export default App;