import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

interface Auth {
    token: string
}

const initialState = {
    token: ""
} as Auth;

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        }
    }
});

export const { setToken } = authSlice.actions;
export const getToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;