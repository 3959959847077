import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

interface CurentSymbol {
    value: string
}

const initialState = {
    value: "AAPL"
} as CurentSymbol;

export const currentSymbolSlice = createSlice({
    name: 'symbol',
    initialState,
    reducers: {
        setValue: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        }
    }
});

export const { setValue } = currentSymbolSlice.actions;
export const getValue = (state: RootState) => state.symbol.value;

export default currentSymbolSlice.reducer;