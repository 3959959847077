import type { RootState } from '../../app/store'

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  items: [],
  status: 'idle',
  error: null
}

export const fetchItems = createAsyncThunk('wheel/fetchScanner', async () => {
  const response = await fetch(process.env.REACT_APP_API_HOST + "/wheel/scanner");
  return response.json();
})

const wheelSlice = createSlice({
    name: 'wheel',
    initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchItems.pending, (state, action) => {
               state.status = 'loading'
            })
            .addCase(fetchItems.fulfilled, (state, action) => {
               state.status = 'succeeded'
                state.items = state.items.concat(action.payload)
            })
            .addCase(fetchItems.rejected, (state, action) => {
               state.status = 'failed'
            })
    }
})

export default wheelSlice.reducer;
export const getItems = (state: RootState) => state.wheel.items;