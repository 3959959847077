// import { useAppDispatch, useAppSelector } from '../../../app/hooks';
// import { useEffect } from "react";
import Popup from 'reactjs-popup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { addSymbol } from './muteListSlice';

const popover = (symbol: string, dispatch: any ) => (
    <Popover id="popover-basic" placement="left" >
        <div className="modal-header">
            <h6 className="modal-title">Mute {symbol} for?</h6>
                <button type="button" className="btn-close " aria-label="Close" onClick={() => document.body.click()}></button>
         </div>
      
      <Popover.Body>
        <div className="btn-group" role="group" aria-label="Mute duration">
            <button type="button" className="btn btn-outline-primary" onClick={() => dispatch(addSymbol({symbol: symbol, expiration: 3600 * 24}))}>Day</button>
            <button type="button" className="btn btn-outline-primary" onClick={() => dispatch(addSymbol({symbol: symbol, expiration: 3600 * 24 * 7}))}>Week</button>
            <button type="button" className="btn btn-outline-primary" onClick={() => dispatch(addSymbol({symbol: symbol, expiration: 3600 * 24 * 30}))}>Month</button>
            <button type="button" className="btn btn-outline-primary" onClick={() => dispatch(addSymbol({symbol: symbol, expiration: 3600 * 24 * 365}))}>Year</button>
        </div>
      </Popover.Body>
    </Popover>
  );

interface MutePopupProps {
    symbol: string
}

export const MutePopup = (props: MutePopupProps) => {
    const dispatch = useAppDispatch();
    const button = <span className="pe-auto"><FontAwesomeIcon icon={faVolumeMute} pull="right" color="grey" /></span>;
    
    return (
        <OverlayTrigger trigger="click" placement="left" overlay={popover(props.symbol, dispatch)} rootClose={true}>
            {button}
        </OverlayTrigger>
    );
} 